<template>
  <div class="template-1">
    <header class="page-header container">
      <h1>{{ mrpProgramInfo.name }}</h1>
      <BannerAid :bannerLink="`links|${mrpSelectedProgramId}|${mrpSelectedCategory}|${mrpSelectedTopicId}`" />
    </header>
    <!-- / Page Header -->
    <b-container class="page-body">
      <b-row class="center-elements">
        <b-col sm="6" class="search-col">
          <search-form :i18n="translations.components" />
        </b-col>
      </b-row>
      <b-row class="table-row">
        <b-col sm="12" class="table-col mt-5">
          <data-table
            :title="tableTitle"
            :tooltip="tooltip"
            :fields="fields"
            :items="links"
            :i18n="translations.components"
          ></data-table>
        </b-col>
      </b-row>
    </b-container>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BannerAid from '@/components/BannerAid.vue'
import dataTable from '@/components/DataTableMRP.vue'
import searchForm from './SearchForm.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'mrp-links',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      tooltip: 'Testing',
      fields: [
        {
          key: 'link_name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'updated',
          label: 'Updated',
          sortable: true,
        },
      ],
    }
  },
  components: {
    BannerAid: BannerAid,
    DataTable: dataTable,
    SearchForm: searchForm,
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      loadMRPProgramInfo: 'mrp/loadMRPProgramInfo',
    }),
    createTitle(code) {
      return {
        ax: 'Auxiliary',
        cm: 'Church Ministry',
        co: 'Officer',
        gc: 'GideonCard',
        ff: 'Faith Fund',
        mb: 'Membership',
        of: 'Officer',
        pw: 'Prayer & Personal Witnessing',
        sd: 'Scripture Distribution',
        st: 'Officer',
        xx: 'Program',
      }[code || 'xx']
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.loadMRPProgramInfo({ lang: this.prefCulture, restype: 'links', programid: this.mrpSelectedProgramId }),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
    },
    async setDataTableFields() {
      const { tcName, tcUpdated } = this.translations.components['data-table-mrp']
      this.fields[0].label = tcName
      this.fields[1].label = tcUpdated
      return true
    },
  },
  computed: {
    ...mapGetters({
      mrpProgramInfo: 'mrp/mrpProgramInfo',
      mrpSelectedCategory: 'mrp/mrpSelectedCategory',
      mrpSelectedProgramId: 'mrp/mrpSelectedProgramId',
      mrpSelectedTopicId: 'mrp/mrpSelectedTopicId',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
    }),
    tableTitle() {
      return this.mrpProgramInfo.linkTitle
    },
    links() {
      let newLinkList = this.mrpProgramInfo.linkListing.map((l) => {
        return {
          category: 'links',
          itemId: l.LinkId,
          link_name: l.LinkName,
          description: l.LinkDescription,
          link_url: l.LinkUrl.replace('[ind_key]', this.userId),
          updated: l.LinkUpdated,
        }
      })

      return newLinkList
    },
  },
  async created() {
    //need a selected mrp program id
    if (!this.mrpSelectedProgramId) {
      this.$router.push('/resources/media-portal/')
    }
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('data-table-mrp', 'mrp-search-form'),
      this.pageLoad(),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
      this.setDataTableFields()
    })
  },
}
</script>

<style lang="scss" scoped>
/* eslint-disable */
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.page {
  .page-body {
    @include breakpoint(sm) {
      margin-top: 3rem;
    }
    h4 {
      margin-bottom: 10px;
    }
  }
}

.table-row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  .table-col {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    background-color: #ffffff;
    padding: 15px 0;
    box-shadow: 0 9px 24px 6px rgba(157, 157, 157, 0.5);
    .tile-col-card {
      flex-grow: 2;
    }
  }
}
</style>
